var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal",attrs:{"size":"lg","centered":"","modal-class":"aqz-modal boutique-modal","hide-footer":true,"header-close-content":"<i class='aqz-icon aqz-icon-04-close'></i>"},on:{"shown":_vm.refreshMap},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.activeBoutique)?_c('div',{staticClass:"wrapp-boutique"},[_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-row',{staticClass:"no-gutters"},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"md":"6"}},[_c('div',{staticClass:"wrapp-boutique__info"},[_c('card-boutique',{attrs:{"boutiqueInfo":_vm.activeBoutique,"viewImage":false,"viewDetail":false,"viewMap":false,"viewWorkingHours":true},scopedSlots:_vm._u([{key:"footer-ctas",fn:function(){return [(_vm.calcRouteUrl)?_c('a',{staticClass:"bcm-link bcm-link-primary",attrs:{"href":_vm.calcRouteUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('direction')))]):_vm._e()]},proxy:true}],null,false,694182342)})],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"wrapp-map"},[_c('GmapMap',{ref:"vueMap",staticClass:"full-map",attrs:{"center":{lat: parseFloat(_vm.activeBoutique.latitude), lng: parseFloat(_vm.activeBoutique.logintude)},"zoom":7,"options":{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false,
                  styles: _vm.mapStyle
                }}},[_c('GmapMarker',{attrs:{"position":{lat: parseFloat(_vm.activeBoutique.latitude), lng: parseFloat(_vm.activeBoutique.logintude)},"clickable":false,"draggable":false,"icon":{ url: require('@/theme/assets/image/pin.svg'), scaledSize: {width: 60, height: 80}}}})],1)],1)])],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }